@import "libs/reset";

@import "app/shared/colors";
@import "app/shared/variables";

@import "app/fonts";
@import "app/typography";
@import "app/layout";

#content {
  max-width: 900px;
  margin: 0 auto;
  margin-top: 20px;
  .option {
    font-size: 24px;
    max-width: 400px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-right: 20px;
    div {
      margin-top: 10px;
      font-size: 18px;
    }
  }
  input {
    width: 100%;
  }
  .error {
    margin-top: 20px;
    color: $error-dark;
    font-weight: bold;
  }
  .upload {
    margin-bottom: 20px;
  }
  button {
    background-color: $blue-02;
    color: white;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    padding: 7px 12px 7px 12px;
    cursor: pointer;
    border: 2px solid $blue;
    border-radius: 3px;
    user-select: none;
    i {
      color: white;
    }
    &.green {
      background-color: $button-bg;
      border-color: $button-green01;
      border-radius: 3px;
    }
    &.red {
      background-color: $button-red02;
      border-color: $button-red02;
    }
    &:hover {
      background-color: transparent;
      color: $gray-01;
      i {
         color: $blue;
      }
    }
    &.disabled {
      background-color: $gray-09;
      color: $gray-04;
      border-color: $gray-09;
      cursor: default;
      i {
        color: $gray-04;
      }
    }
    &.submit {
      border: 2px solid $button-green02;
      i {
        color: $button-green01;
      }
    }
    &.danger {
      background-color: $button-red02;
      border-color: $button-red02;
      &:hover {
        color: white;
        background-color: $button-red01;
        border-color: $button-red01;
      }
    }
    &.green {
      background-color: $button-green02;
      border-color: $button-green02;
      &:hover {
        color: white;
        background-color: $button-green01;
        border-color: $button-green01;
      }
    }
   
  }
  
  p {
    margin-top: 20px;
    margin-bottom: 20px;
  }  
  .loading {
    margin-top: 20px;
    font-weight: bold;
    color: $button-green02;
  }
  textarea {
    width: 100%;
  }

  .dimensions {
    input {
      width: 50px;
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }
}
